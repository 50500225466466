$navigation-bg-color-primary: $dark-blue !default;
$navigation-bg-color-secondary: $black !default;
$navigation-bg-color-action-active: $navigation-bg-color-secondary !default;
$navigation-text-color-primary: $white !default;
$navigation-text-color-secondary: theme-color() !default;
$navigation-text-color-disabled: $gray-300 !default;
$navigation-text-color-light: rgba($white, 0.5) !default;
$navigation-border-default: 1px solid rgba(255, 255, 255, 0.1) !default;

.navigation {
  display: flex;
  flex-direction: row;

  .side-bar-layout & {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
  }

  &__action {
    color: $white;
    display: block;
    @include border-radius(4px);

    .icon {
      color: $white;
      font-size: 1.6rem;
    }

    &--active {
      .icon {
        color: $primary;
      }
    }

    &--disabled {
      .icon {
        color: $gray-400;
      }
      border: none;
    }
  }

  &__main {
    width: 64px;
    padding: (get-space(3)) get-space(2) get-space(2) get-space(2);
    background-color: $navigation-bg-color-primary;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  &__account {
    text-align: center;
  }

  &__actions {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__secondary {
    width: 200px;
    background-color: $navigation-bg-color-secondary;
    overflow-y: auto;
  }

  &__brand {
    text-align: center;
    padding-bottom: get-space(3);
  }

  &__brand-logo {
    //spacing convention exception
    padding: 26px 32px 18px 32px;

    a {
      line-height: get-space(2);
      display: block;
    }

    &-img {
      height: 48px;
      width: auto;
    }
  }

  &__brand-text {
    color: $navigation-text-color-primary;
    letter-spacing: 0.91px;
    line-height: 14px;
    display: block;
  }

  &__light-title {
    color: $navigation-text-color-light;
    margin-bottom: get-space(2);
    margin-left: get-space(2);
  }

  &__label {
    color: $navigation-text-color-light;

    &--highlight {
      color: $navigation-text-color-secondary;
    }
  }

  &__back {
    margin-left: -6px;
  }

  &__link {
    display: flex;
    align-items: center;
    font-family: $font-family-secondary;
    color: $navigation-text-color-primary;
    text-decoration: none;
    margin-bottom: get-space(1);
    padding: get-space(2);
    @include border-radius(4px);
    @include transition(background-color 300ms ease-in);

    .icon {
      color: $navigation-text-color-primary;
    }

    .permissions-icon {
      color: $navigation-text-color-disabled;
    }

    &:hover,
    &--active {
      cursor: pointer;
      background-color: $navigation-bg-color-primary;
    }

    &__disabled {
      cursor: default;
      display: flex;
      align-items: center;
      font-family: $font-family-secondary;
      color: $navigation-text-color-disabled;
      text-decoration: none;
      margin-bottom: get-space(1);
      padding: get-space(2);
      @include border-radius(4px);

      &:hover {
        background-color: transparent;
      }

      &__tooltip {
        color: $black;
        width: 180px;
      }
    }
  }
}

.navigation-view__action--content {
  z-index: 1;
}

.navigation-view__action--content label {
  z-index: 9999999 !important;
}

.tooltip__element {
  overflow: visible !important;
}

.tooltip {
  z-index: 999999;
}

.accordion .tooltip {
  z-index: 99998 !important;
}
