.project-nav-list,
.programme-nav-list {
  &__search .input {
    box-shadow: 0 1px 0 0 $white !important;
    color: $white !important;
  }

  &__search .icon {
    color: $white;
  }

  &__create-link {
    text-decoration: underline;

    &:hover {
      color: $viking;
    }
  }
  .project-mega-menu__wrapper {
    width: 100%;
    & > * {
      padding: get-space(1) get-space(3);
    }
  }
}
