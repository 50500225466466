.mood {
  border-radius: 15px;
  border: 1px solid grey;
  background: #e6e6e6;
  padding: 15px;
  text-align: center;
  display: inline-block;
  outline: none;
}

.default {
  border: 1px solid transparent !important;
  padding: 4px;
  color: $black;
}

.selected {
  border: 1px solid lightgreen !important;
  padding: 4px;
}

.grid-cell {
  & > * {
    margin: 0;
  }
}

.simple-input-editor {
  width: 100%;
  height: 100%;
}

.ag-theme-alpine {
  font-family: $font-family-primary;

  .ag-cell:not(:focus) {
    border-right: 1px solid #dde2eb !important;
  }

  .ag-header {
    position: sticky;
  }

  .hover-link:hover {
    color: $astral;
    font-weight: 600;
  }

  .ag-header-row,
  .ag-header-container,
  .ag-header-viewport {
    border: none;
  }

  .ag-header-cell-text {
    @extend %h5;
    margin-bottom: 0;
  }

  .ag-header-cell-resize::after {
    height: 100%;
    top: 0;
  }

  .ag-header {
    background-color: #edeff2;
  }
}

.ag-theme-alpine .ag-root-wrapper {
  overflow: visible !important;
}

.ag-theme-alpine .user-active-cell {
  border: 3px solid !important;
  &--edit {
    background-color: $gray-300;
  }
  $user-cell-selected-colours: $purple, $pink, $red, $orange, $yellow, $green;
  @for $i from 1 through (length($user-cell-selected-colours) * 7) {
    &--#{$i} {
      $k: $i % 6;
      @if $k == 0 {
        $k: 1;
      }
      border-color: nth($user-cell-selected-colours, $k) !important;
      border-right: 3px solid nth($user-cell-selected-colours, $k) !important;
      &.ag-cell:not(:focus) {
        border-right: 3px solid nth($user-cell-selected-colours, $k) !important;
      }
    }
  }
}

// .modal {
//   .ag-theme-alpine {
//     height: 700px !important;
//   }
// }

.modal {
  .ag-theme-alpine {
    max-height: 800px !important;
  }
}
.ag-body-viewport.ag-layout-normal {
  padding-bottom: 400px;
}

.ag-cell-wrap-text .ag-cell-wrapper {
  line-height: 1.8;
  word-break: break-word !important;

  & > * {
    padding: 8px 0px;
  }
}

.ag-cell--not-editable {
  opacity: 0.7;
  font-style: italic;
  background: $gray-100;
}

.modal-grid-toolbar {
  .ui-input {
    display: inline-block;
    width: 230px;
  }
}
